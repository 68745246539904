import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'

Vue.use(InfiniteLoading, {
  slots: {
    noMore: '',
    noResults: '',
    error: 'Iets ging er niet goed, probeer het later nog eens.'
  }
})
