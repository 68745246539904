import wordpress from '../service/wordpress'

export const strict = false

export const state = () => ({
  language: 'nl',
  bars: [],
  categories: [],
  products: []
})

export const getters = {
  getLanguage(state) {
    return state.language
  },
  getBars(state) {
    return state.bars
  },
  getCategories(state) {
    return state.categories
  },
  getProducts(state) {
    return state.products
  }
}

export const mutations = {
  SET_LANGUAGE(state, language) {
    state.language = language
  },
  SET_BARS(state, bars) {
    state.bars = bars
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_PRODUCTS(state, products) {
    state.products = products
  }
}

export const actions = {
  async nuxtServerInit({ commit }, { redirect, app, req }) {
    let language = req._parsedUrl.path.split('/')
    language.shift()
    language = language[0]

    if (language.length === 2) {
      app.$cookies.set('language', language, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365
      })
    } else if (language.length < 2) {
      language = app.$cookies.get('language')
        ? app.$cookies.get('language')
        : 'nl'
    } else {
      language = app.$cookies.get('language')
        ? app.$cookies.get('language')
        : 'nl'
      if (language === 'nl') {
        redirect(`${req._parsedUrl.path}`)
      } else {
        redirect(`/${language}${req._parsedUrl.path}`)
      }
    }

    if (req._parsedUrl.path.length < 2) {
      if (language === 'nl') {
        redirect(`/`)
      } else {
        redirect(`/${language}`)
      }
    }
    commit('SET_LANGUAGE', language)

    const [bars, categories, pages, products] = await Promise.all([
      wordpress.get(`bars`),
      wordpress.get(
        `product_categories?per_page=1000&order=asc&orderby=menu_order`
      ),
      wordpress.get(`pages?per_page=1000&order=asc&orderby=menu_order`),
      wordpress.get(`products?per_page=1000&order=asc&orderby=menu_order`)
    ])

    const pagesData = pages.data
    const newCategories = []
    for (let i = 0; i < pagesData.length; i++) {
      if (pagesData[i].parent === 'collectie') {
        const category = {}
        category.page = pagesData[i]
        category.categories = []
        for (let j = 0; j < categories.data.length; j++) {
          for (let k = 0; k < pagesData[i].acf.view_products.length; k++) {
            if (
              pagesData[i].acf.view_products[k].post_name ===
              categories.data[j].slug
            ) {
              category.categories.push(categories.data[j])
            }
          }
        }
        newCategories.push(category)
      }
    }
    for (let i = 0; i < newCategories.length; i++) {
      newCategories[i].products = []
      products.data.map((item) => {
        for (let j = 0; j < item.acf.category.length; j++) {
          for (let k = 0; k < newCategories[i].categories.length; k++) {
            if (
              newCategories[i].categories[k].slug ===
              item.acf.category[j].post_name
            ) {
              newCategories[i].products.push(item)
            }
          }
        }
      })
    }
    commit('SET_BARS', bars.data)
    commit('SET_CATEGORIES', newCategories)
    commit('SET_PRODUCTS', products.data)
  }
}
