import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _46dd9cfe = () => interopDefault(import('../pages/collection/index.vue' /* webpackChunkName: "pages/collection/index" */))
const _1973ec70 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _709ff7d8 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _0db99364 = () => interopDefault(import('../pages/social-media.vue' /* webpackChunkName: "pages/social-media" */))
const _0df94154 = () => interopDefault(import('../pages/outlets.vue' /* webpackChunkName: "pages/outlets" */))
const _53fcf075 = () => interopDefault(import('../pages/collection/_products/index.vue' /* webpackChunkName: "pages/collection/_products/index" */))
const _7253fff0 = () => interopDefault(import('../pages/collection/_products/_slug/index.vue' /* webpackChunkName: "pages/collection/_products/_slug/index" */))
const _5ee05622 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/collectie",
    component: _46dd9cfe,
    name: "collection___nl"
  }, {
    path: "/contact",
    component: _1973ec70,
    name: "contact___nl"
  }, {
    path: "/privacybeleid",
    component: _709ff7d8,
    name: "privacy___nl"
  }, {
    path: "/social-media",
    component: _0db99364,
    name: "social-media___nl"
  }, {
    path: "/verkooppunten",
    component: _0df94154,
    name: "outlets___nl"
  }, {
    path: "/collectie/:products",
    component: _53fcf075,
    name: "collection-products___nl"
  }, {
    path: "/collectie/:products/:slug",
    component: _7253fff0,
    name: "collection-products-slug___nl"
  }, {
    path: "/",
    component: _5ee05622,
    name: "index___nl"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
