import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_vuescrollto_4f3fed66 from 'nuxt_plugin_vuescrollto_4f3fed66' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_toast_0e8aa42a from 'nuxt_plugin_toast_0e8aa42a' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_googlegtag_28719a2a from 'nuxt_plugin_googlegtag_28719a2a' // Source: ./google-gtag.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_db1bee38 from 'nuxt_plugin_cookieuniversalnuxt_db1bee38' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginrouting_dd7ad3ec from 'nuxt_plugin_pluginrouting_dd7ad3ec' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_27a9bcb6 from 'nuxt_plugin_pluginmain_27a9bcb6' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_2c8a613c from 'nuxt_plugin_axios_2c8a613c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_vueagile_726cdbff from 'nuxt_plugin_vueagile_726cdbff' // Source: ../plugins/vue-agile (mode: 'client')
import nuxt_plugin_vuecookielaw_6b17b656 from 'nuxt_plugin_vuecookielaw_6b17b656' // Source: ../plugins/vue-cookie-law (mode: 'client')
import nuxt_plugin_vuegallery_840f0656 from 'nuxt_plugin_vuegallery_840f0656' // Source: ../plugins/vue-gallery (mode: 'client')
import nuxt_plugin_vuejslazyload_eb553b3e from 'nuxt_plugin_vuejslazyload_eb553b3e' // Source: ../plugins/vue-js-lazyload (mode: 'client')
import nuxt_plugin_vuejsmodal_f50827f4 from 'nuxt_plugin_vuejsmodal_f50827f4' // Source: ../plugins/vue-js-modal (mode: 'all')
import nuxt_plugin_vueshowslide_91ccbb04 from 'nuxt_plugin_vueshowslide_91ccbb04' // Source: ../plugins/vue-show-slide (mode: 'client')
import nuxt_plugin_vuemasonry_087bf870 from 'nuxt_plugin_vuemasonry_087bf870' // Source: ../plugins/vue-masonry (mode: 'client')
import nuxt_plugin_vueinfiniteloading_ba354f40 from 'nuxt_plugin_vueinfiniteloading_ba354f40' // Source: ../plugins/vue-infinite-loading (mode: 'client')
import nuxt_plugin_gmaps_34c7cc70 from 'nuxt_plugin_gmaps_34c7cc70' // Source: ../plugins/gmaps (mode: 'client')
import nuxt_plugin_plugin_7bba9178 from 'nuxt_plugin_plugin_7bba9178' // Source: ./auth/plugin.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp (ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"QOPPS • outdoor furniture •","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"QOPPS • outdoor furniture •"},{"hid":"msapplication-TileColor","name":"msapplication-TileColor","content":"#ffffff"},{"hid":"msapplication-TileImage","name":"msapplication-TileImage","content":"\u002Fmstile-144x144.png"},{"hid":"theme-color","name":"theme-color","content":"#68715b"}],"script":[{"src":"\u002Fjs\u002FobjectFitPolyfill.min.js"},{"src":"https:\u002F\u002Fwww.googletagmanager.com\u002Fgtag\u002Fjs?id=GTM-TJFV8S3","async":true}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"apple-touch-icon","sizes":"57x57","href":"\u002Fapple-touch-icon-57x57.png"},{"rel":"apple-touch-icon","sizes":"60x60","href":"\u002Fapple-touch-icon-60x60.png"},{"rel":"apple-touch-icon","sizes":"72x72","href":"\u002Fapple-touch-icon-72x72.png"},{"rel":"apple-touch-icon","sizes":"76x76","href":"\u002Fapple-touch-icon-76x76.png"},{"rel":"apple-touch-icon","sizes":"114x114","href":"\u002Fapple-touch-icon-114x114.png"},{"rel":"apple-touch-icon","sizes":"120x120","href":"\u002Fapple-touch-icon-120x120.png"},{"rel":"apple-touch-icon","sizes":"144x144","href":"\u002Fapple-touch-icon-144x144.png"},{"rel":"apple-touch-icon","sizes":"152x152","href":"\u002Fapple-touch-icon-152x152.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Fapple-touch-icon-180x180.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"192x192","href":"\u002Fandroid-icon-192x192.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"96x96","href":"\u002Ffavicon-96x96.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ffavicon-16x16.png"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (process.client && typeof nuxt_plugin_vuescrollto_4f3fed66 === 'function') {
    await nuxt_plugin_vuescrollto_4f3fed66(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_0e8aa42a === 'function') {
    await nuxt_plugin_toast_0e8aa42a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googlegtag_28719a2a === 'function') {
    await nuxt_plugin_googlegtag_28719a2a(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_db1bee38 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_db1bee38(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_dd7ad3ec === 'function') {
    await nuxt_plugin_pluginrouting_dd7ad3ec(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_27a9bcb6 === 'function') {
    await nuxt_plugin_pluginmain_27a9bcb6(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2c8a613c === 'function') {
    await nuxt_plugin_axios_2c8a613c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueagile_726cdbff === 'function') {
    await nuxt_plugin_vueagile_726cdbff(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecookielaw_6b17b656 === 'function') {
    await nuxt_plugin_vuecookielaw_6b17b656(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuegallery_840f0656 === 'function') {
    await nuxt_plugin_vuegallery_840f0656(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuejslazyload_eb553b3e === 'function') {
    await nuxt_plugin_vuejslazyload_eb553b3e(app.context, inject)
  }

  if (typeof nuxt_plugin_vuejsmodal_f50827f4 === 'function') {
    await nuxt_plugin_vuejsmodal_f50827f4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueshowslide_91ccbb04 === 'function') {
    await nuxt_plugin_vueshowslide_91ccbb04(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuemasonry_087bf870 === 'function') {
    await nuxt_plugin_vuemasonry_087bf870(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueinfiniteloading_ba354f40 === 'function') {
    await nuxt_plugin_vueinfiniteloading_ba354f40(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gmaps_34c7cc70 === 'function') {
    await nuxt_plugin_gmaps_34c7cc70(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_7bba9178 === 'function') {
    await nuxt_plugin_plugin_7bba9178(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
