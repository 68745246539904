export const vueI18n = {"fallbackLocale":"nl","messages":{"nl":{"404":{"header":"Pagina niet gevonden","text":"Oeps, er is iets fout gegaan. De pagina die je hebt opgevraagd bestaat niet of geeft een foutmelding. Ga terug naar onze <a href='/'>homepage</a>."},"components":{"menu":{"contact":"Contact","social_media":"Social media","outlets":"Verkooppunten"},"footer":{"follow_us":"Volg ons","follow_us_text":"Op <a href='#'><strong>Instagram</strong></a> en <a href='#'><strong>Pinterest</strong></a>.","collections":"Collecties","contact":"Contact","contact_text":"Bel <a href=\"tel:0031413272914\">0413 272 914</a> of mail naar <a href=\"mailto:info@qopps.nl\">info@qopps.nl</a>.","find_outlet":"Vind een verkooppunt bij u in de buurt."},"products":{"more_information":"Meer informatie","find_outlet":"Vind verkooppunt","question":"Ik heb een vraag over dit artikel","sku":"Art. nr","description":"Product omschrijving","linked_products":"anderen bekeken ook","new":"Nieuw"},"stores":{"header":"Vind een verkooppunt bij jou in de buurt!","button":"Zoek een verkooppunt","address":"Voer je adres in","search":"Zoeken","search_close":"In de buurt","product":"product","products":"producten","directions":"Route","website":"Bezoek website"}},"form":{"first_name":"Voornaam","last_name":"Achternaam","phone":"Telefoonnummer","email_address":"E-mailadres","message":"Bericht","send":"Verzenden","thank_you":"Bedankt voor je bericht, we zullen deze zo spoedig mogelijk behandelen","fail":"Er is iets fout gegaan bij het verzenden, probeer het nogmaals","false_email":"Het e-mailadres is niet correct"},"cookies":{"buttonText":"Toestaan","linkText":"Lees meer","message":"Met behulp van cookies kunnen we analyses maken en uw ervaring op onze website verbeteren. Bij verder gebruik van onze website gaat u hiermee akkoord."}}}}
export const vueI18nLoader = false
export const locales = [{"code":"nl","name":"NL","file":"./assets/locales/nl-NL.json"}]
export const defaultLocale = 'nl'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = null
export const rootRedirect = null
export const detectBrowserLanguage = false
export const differentDomains = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = false
export const pages = {"index":{"nl":"/"},"collection/index":{"nl":"/collectie"},"collection/_products/index":{"nl":"/collectie/:products"},"collection/_products/_slug/index":{"nl":"/collectie/:products/:slug"},"outlets":{"nl":"/verkooppunten"},"privacy":{"nl":"/privacybeleid"}}
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["nl"]
